define("frontend/controllers/documents/document", ["exports", "jquery", "frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    documents: Ember.inject.controller(),
    application: Ember.inject.controller(),
    bugReporting: Ember.inject.service(),
    zoomValue: Ember.computed.alias('documents.zoomValue'),
    isLoading: Ember.computed.alias('documents.isLoading'),
    rotatedDegrees: Ember.computed.alias('documents.rotatedDegrees'),
    pageNumber: 1,
    setupController: function setupController() {
      this.reload();
    },
    logDocumentView: Ember.observer('model.id', function () {
      this.storeBugReportingData('view ' + this.model.id);
    }),
    user: Ember.computed.alias('application.user'),
    isLocked: Ember.computed.alias('model.lock.id'),
    thisUserId: Ember.computed.alias('user.id'),
    lockedUserId: Ember.computed.alias('model.lock.user.id'),
    isLockMine: Ember.computed('lockedUserId', 'thisUserId', function () {
      return this.get('lockedUserId') === this.get('thisUserId');
    }),
    isLockNotMine: Ember.computed.not('isLockMine'),
    isLockedByOther: Ember.computed.and('isLocked', 'isLockNotMine'),
    storeBugReportingData: function storeBugReportingData(action) {
      if (action) {
        this.get('bugReporting.actions').push(action);
      }

      this.set('bugReporting.data.document', {
        document: this.get('document.id'),
        pageNumber: this.get('pageNumber'),
        isSaving: this.get('isSaving')
      });
      this.set('bugReporting.data.ownerAttributes', this.get('application.ownerAttributes'));
      this.incrementProperty('bugReporting.version');
      this.documents.storeBugReportingData();
    },
    actions: {
      saveAction: function saveAction() {
        var _this = this;

        if (this.get('isSaving')) {
          this.storeBugReportingData('save denied');
          return;
        }

        this.storeBugReportingData('begin saving');
        this.set('isSaving', true); // Set currently selected owner so that the backend knows which owner to use for validation

        this.model.set('owner', this.get('application.user.owner'));
        this.model.set('owner_id', this.get('application.user.owner.id'));
        this.model.save().then(function () {
          M.toast({
            html: 'Dokumentet har sparats',
            classes: 'green darken-1 rounded'
          });

          _this.set('isSaving', false);

          _this.storeBugReportingData('save successful');
        }, function (response) {
          if (response && response.errors && response.errors.length && response.errors[0].status === '423') {
            M.toast({
              html: 'Dokumentet kan inte sparas eftersom det är låst av någon annan än dig',
              classes: 'red darken-1 rounded'
            });
          } else if (response && response.errors && response.errors.length && response.errors[0].status === '403') {
            M.toast({
              html: 'Dokumentet kan inte sparas eftersom du ej får lov att redigera detta dokument',
              classes: 'red darken-1 rounded'
            });
          } else {
            M.toast({
              html: 'Dokumentet kunde inte sparas',
              classes: 'red darken-1 rounded'
            });
          }

          _this.set('isSaving', false);

          _this.storeBugReportingData('save error');
        });
      },
      saveCopyAction: function saveCopyAction() {
        var _this2 = this;

        if (this.get('isSaving')) {
          this.storeBugReportingData('save copy denied');
          return;
        }

        this.storeBugReportingData('begin save copy');
        this.set('isSaving', true);
        var that = this;
        var properties = this.model.getAllProperties();
        var attrPromise = new Promise(function (resolve) {
          var attributes = [];
          properties.attr_values.then(function (attrs) {
            attrs.forEach(function (attrValue) {
              var attr = attrValue.get('attr');
              var value = attrValue.get('value') !== undefined ? attrValue.get('value') : '';
              var valid = attrValue.get('valid') ? '1' : '0';
              attributes.push({
                id: attr.get('id'),
                name: attr.get('attr_ingest_name'),
                value: value,
                valid: valid
              });
            });
            resolve(attributes);
          });
        });
        var ownerId = this.get('user.owner_id') || -1;
        attrPromise.then(function (attributes) {
          _jquery.default.ajax(_environment.default.APP.apiHost + '/api/documents/save-copy/' + _this2.model.get('id'), {
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            method: 'POST',
            data: {
              attributes: attributes,
              ownerId: ownerId
            },
            success: function success(response) {
              // Reset the models attributes back to their original values before creating the copy
              if (response && response.originalAttributes && Array.isArray(response.originalAttributes)) {
                properties.attr_values.then(function (attrs) {
                  attrs.forEach(function (attrValue) {
                    var noAttrFound = 0;
                    var modelAttr = attrValue.get('attr');
                    response.originalAttributes.forEach(function (origAttr) {
                      if (origAttr.attr_id === modelAttr.get('id')) {
                        attrValue.set('value', origAttr.value);
                      } else {
                        noAttrFound++;
                      }

                      if (noAttrFound === response.originalAttributes.length) {
                        attrValue.set('value');
                      }
                    });
                  });
                });
              }

              that.model.set('isDirty', false);
              M.toast({
                html: 'En kopia av dokumentet har skapats',
                classes: 'green darken-1 rounded'
              });
              that.storeBugReportingData('save copy successful');
            },
            statusCode: {
              400: function _() {
                that.storeBugReportingData('save copy error 400');
                M.toast({
                  html: 'En kopia av dokumentet kunde ej skapas',
                  classes: 'red darken-1 rounded'
                });
              },
              401: function _() {
                that.storeBugReportingData('save copy error 401');
                M.toast({
                  html: 'Du har ej behörighet för att skapa en kopia av detta dokument',
                  classes: 'red darken-1 rounded'
                });
              },
              403: function _() {
                that.storeBugReportingData('save copy error 403');
                M.toast({
                  html: 'Det är förbjudet att skapa en kopia av detta dokument',
                  classes: 'red darken-1 rounded'
                });
              },
              404: function _() {
                that.storeBugReportingData('save copy error 404');
                M.toast({
                  html: 'Detta dokument kunde ej hittas och därmed skapades ingen kopia',
                  classes: 'red darken-1 rounded'
                });
              },
              422: function _(jqXHR) {
                if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.errors && jqXHR.responseJSON.errors.length && Array.isArray(jqXHR.responseJSON.errors)) {
                  var errors = jqXHR.responseJSON.errors;
                  properties.attr_values.then(function (attrs) {
                    attrs.forEach(function (attr) {
                      attr.get('attr').then(function (attrVal) {
                        var attrId = Number.parseInt(attrVal.get('id'));

                        for (var i = 0; i < errors.length; i++) {
                          var errorId = Number.parseInt(errors[i].source.id);
                          if (errorId !== attrId) continue;
                          var attrErrors = attr.get('errors');
                          attrErrors.pushObject({
                            message: errors[i].detail
                          });
                          break;
                        }
                      });
                    });
                  });
                }

                M.toast({
                  html: 'Minst ett attribut var felangivet',
                  classes: 'red darken-1 rounded'
                });
                that.storeBugReportingData('save copy error 422');
              },
              500: function _() {
                that.storeBugReportingData('save copy error 500');
                M.toast({
                  html: 'Internt serverfel vid försök att skapa kopia',
                  classes: 'red darken-1 rounded'
                });
              }
            },
            error: function error(jqXHR) {
              switch (jqXHR.status) {
                case 400:
                case 401:
                case 403:
                case 404:
                case 422:
                case 500:
                  return;
              }

              that.storeBugReportingData('save copy error ' + jqXHR.status);
              M.toast({
                html: 'Något gick fel, en kopia av dokumentet kunde ej skapas.',
                classes: 'red darken-1 rounded'
              });
            },
            complete: function complete() {
              that.set('isSaving', false);
              that.storeBugReportingData('save copy complete');
            }
          });
        });
      },
      saveToListAction: function saveToListAction() {
        var _this3 = this;

        if (this.get('isSaving')) {
          this.storeBugReportingData('save to list denied');
          return;
        }

        this.storeBugReportingData('begin save to list');
        this.set('isSaving', true); // Set currently selected owner so that the backend knows which owner to use for validation

        this.model.set('owner', this.get('application.user.owner'));
        this.model.set('owner_id', this.get('application.user.owner.id'));
        this.model.save().then(function () {
          _this3.storeBugReportingData('save to list successful');

          M.toast({
            html: 'Dokumentet har sparats, går till dokumentlistan',
            classes: 'green darken-1 rounded'
          });

          _this3.send('backToList');

          _this3.set('isSaving', false);
        }, function (response) {
          if (response && response.errors && response.errors.length && response.errors[0].status === '423') {
            M.toast({
              html: 'Dokumentet kan inte sparas eftersom det är låst av någon annan än dig',
              classes: 'red darken-1 rounded'
            });
          } else if (response && response.errors && response.errors.length && response.errors[0].status === '403') {
            M.toast({
              html: 'Dokumentet kan inte sparas eftersom du ej får lov att redigera detta dokument',
              classes: 'red darken-1 rounded'
            });
          } else {
            M.toast({
              html: 'Dokumentet kunde inte sparas',
              classes: 'red darken-1 rounded'
            });
          }

          _this3.set('isSaving', false);

          _this3.storeBugReportingData('save to list error');
        });
      },
      saveToNextAction: function saveToNextAction() {
        var _this4 = this;

        if (this.get('isSaving')) {
          this.storeBugReportingData('save to next denied');
          return;
        }

        this.storeBugReportingData('begin save to next');
        this.set('isSaving', true); // Set currently selected owner so that the backend knows which owner to use for validation

        this.model.set('owner', this.get('application.user.owner'));
        this.model.set('owner_id', this.get('application.user.owner.id'));
        this.model.save().then(function (rsp) {
          M.toast({
            html: 'Dokumentet har sparats, går till nästa',
            classes: 'green darken-1 rounded'
          });

          _this4.send('nextUnlockedDocumentAction', _this4.rotatedDegrees);

          _this4.set('isSaving', false);

          _this4.storeBugReportingData('save to next successful');
        }, function (response) {
          if (response && response.errors && response.errors.length && response.errors[0].status === '423') {
            M.toast({
              html: 'Dokumentet kan inte sparas eftersom det är låst av någon annan än dig',
              classes: 'red darken-1 rounded'
            });
          } else if (response && response.errors && response.errors.length && response.errors[0].status === '403') {
            M.toast({
              html: 'Dokumentet kan inte sparas eftersom du ej får lov att redigera detta dokument',
              classes: 'red darken-1 rounded'
            });
          } else {
            M.toast({
              html: 'Dokumentet kunde inte sparas',
              classes: 'red darken-1 rounded'
            });
          }

          _this4.set('isSaving', false);

          _this4.storeBugReportingData('save to next error');
        });
      },
      deleteDocumentAction: function deleteDocumentAction() {
        var _this5 = this;

        if (!window.confirm('Är du säker på att du vill radera dokumentet?')) {
          this.storeBugReportingData('delete document not confirmed');
          return;
        }

        this.storeBugReportingData('delete document confirmed');
        this.model.deleteRecord();
        this.model.save().then(function () {
          M.toast({
            html: 'Dokumentet har raderats',
            classes: 'green darken-1 rounded'
          });

          _this5.get('documents').removeDocument(_this5.model);

          var documentsLength = _this5.get('documents.documents').length;

          var lastDocumentIndex = documentsLength - 1;

          if (_this5.get('documents.index') <= lastDocumentIndex) {
            _this5.send('transitionToDocument');
          } else {
            _this5.send('backToList');
          }

          _this5.storeBugReportingData('delete document successful');
        }, function (response) {
          _this5.model.rollback();

          if (response && response.errors && response.errors.length && response.errors[0].status === '423') {
            M.toast({
              html: 'Dokumentet kan inte raderas eftersom det är låst av någon annan än dig',
              classes: 'red darken-1 rounded'
            });
          } else {
            M.toast({
              html: 'Dokumentet kunde inte raderas',
              classes: 'red darken-1 rounded'
            });
          }

          _this5.storeBugReportingData('delete document error');
        });
      },
      rollbackAction: function rollbackAction() {
        this.storeBugReportingData('rollback');
        this.model.get('attr_values').then(function (values) {
          values.invoke('rollbackAttributes');
        });
        this.model.rollbackAttributes();
      },
      nextPageAction: function nextPageAction() {
        var pageCount = this.model.get('page_count');
        if (pageCount <= 1) return false;
        var currentPage = this.get('pageNumber');
        var nextPage = currentPage + 1;

        if (currentPage < pageCount) {
          this.set('pageNumber', nextPage);
          this.model.set('isFirstPage', nextPage === 1);
          this.model.set('isLastPage', nextPage === pageCount);
        }

        this.storeBugReportingData('next page');
      },
      prevPageAction: function prevPageAction() {
        var pageCount = this.model.get('page_count');
        if (pageCount <= 1) return false;
        var currentPage = this.get('pageNumber');
        var nextPage = currentPage - 1;

        if (currentPage > 1) {
          this.set('pageNumber', nextPage);
          this.model.set('isFirstPage', nextPage === 1);
          this.model.set('isLastPage', nextPage === pageCount);
        }

        this.storeBugReportingData('prev page');
      },
      resetPageNumberAction: function resetPageNumberAction() {
        this.set('pageNumber', 1);
        this.model.set('isFirstPage', true);
        this.model.set('isLastPage', false);
      },
      restoreDocumentAction: function restoreDocumentAction() {
        var _this6 = this;

        if (this.get('isSaving')) {
          this.storeBugReportingData('restore document denied');
          return;
        }

        this.set('isSaving', true);

        if (!window.confirm('Är du säker på att du vill återställa dokumentet?')) {
          this.storeBugReportingData('restore document not confirmed');
          return;
        }

        this.storeBugReportingData('restore document confirmed'); // Set currently selected owner so that the backend knows which owner to use for validation

        this.model.set('owner', this.get('application.user.owner'));
        this.model.set('owner_id', this.get('application.user.owner.id'));
        this.model.set('deleted', false);
        this.model.save().then(function () {
          _this6.set('isSaving', false);

          M.toast({
            html: 'Dokumentet har återställts',
            classes: 'green darken-1 rounded'
          });

          _this6.get('documents').restoreDocument(_this6.model);

          var documentsLength = _this6.get('documents.documents').length;

          var lastDocumentIndex = documentsLength - 1;

          if (_this6.get('documents.index') <= lastDocumentIndex) {
            _this6.send('transitionToDocument');
          } else {
            _this6.send('backToList');
          }

          _this6.storeBugReportingData('restore document successful');
        }, function (response) {
          if (response && response.errors && response.errors.length && response.errors[0].status === '423') {
            M.toast({
              html: 'Dokumentet kan inte återställas eftersom det är låst av någon annan än dig',
              classes: 'red darken-1 rounded'
            });
          } else {
            M.toast({
              html: 'Dokumentet kunde inte återställas',
              classes: 'red darken-1 rounded'
            });
          }

          _this6.set('isSaving', false);

          _this6.storeBugReportingData('restore document error');
        });
      }
    }
  });

  _exports.default = _default;
});